import cloneDeep from 'lodash/cloneDeep';
import { Providers, Entities } from '@flightscope/baseball-stats';
import { GenericStrikeZone } from '@/enums/Generic';
import { Layouts } from '@/components/ui/charts/plotly/PlotlyHelpers';
import { ChartTypeEnum } from '@/components/ui/charts/ChartHelpers';

// gives access to unit system, plotly chart
import ChartMiddlewareVue from '@/components/ui/charts/middleware/PlotlyChartMiddleware.vue';

export default {
  name: 'PitchLocationChart',

  extends: ChartMiddlewareVue,

  data() {
    const scatterChartLayout = cloneDeep(Layouts.scatterChart);
    return {
      adjustRanges: true,
      chartType: ChartTypeEnum.PITCH_LOCATION.type,
      strikeZoneModel: new GenericStrikeZone(),
      plotlyLayoutExtended: {
        ...scatterChartLayout,
        xaxis: {
          ...scatterChartLayout.xaxis,
          zeroline: false,
        },
        yaxis:{
          ...scatterChartLayout.yaxis,
          zerolinewidth: 1.5,
        },
      },
    };
  },

  methods: {
    seriesExtended(series) {
      let strikeZoneOutline = Providers.StrikeZoneOutlineSeriesProvider(this.strikeZoneModel, false);
      let sectors = Providers.StrikeZoneSectorsGridSeriesProvider(this.strikeZoneModel, false);
      let chartSeries = new Entities.ChartSeries([strikeZoneOutline, sectors]);

      return chartSeries.concat(series);
    },
    customMapping(converted) {
      this.plotlyData = converted.map(this.mapDefaultTraceSettings);

      let symbol = this.unitSymbolX(this.unitSystem.system);

      this.plotlyLayoutExtended.title.text = this.$vuetify.lang.t('$vuetify.pitchLocationChart');
      this.plotlyLayoutExtended.xaxis.title = `${this.xAxisTitle}, ${symbol}`;
      this.plotlyLayoutExtended.yaxis.title = `${this.yAxisTitle}, ${symbol}`;
      this.plotlyLayoutExtended.colorway = this.getColorsFromSeries(this.plotlyData);
    },
  },
};
